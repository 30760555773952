import { env } from '@/utils/Env';
import * as Sentry from '@sentry/vue';

export class SentryHelper {
  static registerSentry(app) {
    if (env('SENTRY_PUBLIC_DSN', false) && env('SENTRY_ENVIRONMENT', false)) {
      Sentry.init({
        app,
        environment: env('SENTRY_ENVIRONMENT'),
        dsn: env('SENTRY_PUBLIC_DSN'),
        integrations: [Sentry.replayIntegration()],
        tracesSampleRate: 0.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }
}

const registerSentry = SentryHelper.registerSentry;

export { registerSentry };
